<template>
  <div class="bg-main pb-5">    
    <b-container class="pt-4 pb-4 my-4">
      <div class="col-md-8 offset-md-2 text-center my-5">
        <h3 class="text-danger">Validation de l'adresse email</h3>
      </div>
      <div class="col-md-8 offset-md-2">
        <b-card class="border-none">
          <h6 class="text-danger text-center py-4">Veuillez entrer le code sécurité que vous avez reçu dans votre courriel</h6>
          <div>
            <b-form>
              <b-form-group
                id="input-group-1"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="code"
                  type="text"
                  placeholder="Enter le code de validation"
                  required
                ></b-form-input>
              </b-form-group>
            </b-form>
          </div>
        <div class="d-flex justify-content-center mt-4">
          <b-btn @click="submit" variant="danger">Vérifier le code</b-btn>
        </div>
      </b-card>
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      code: ''
    }
  },
  methods: {
    ...mapActions({
      verifyCode:'emailVerifier/verifyCode'
    }),
    submit () {
      this.verifyCode(this.code).then(data => {
        this.$router.push({ name: 'stamp.mode' })
        console.log('CODE: ', data)
      }).catch(err => {
        console.log(err)
      } )
    }
  }
}
</script>